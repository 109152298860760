import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero/hero";
import Row from "../components/row/row";
import { StaticImage } from "gatsby-plugin-image";

export default function Classes() {
    return (
        <Layout pageTitle={'Classes'}>
            <Hero titleText={`Classes & Retreats`} subText={`SIGN ME UP!`}>
                <StaticImage
                    src={`../images/classes.png`}
                    alt="A picture of Heidi's classes"
                    placeholder="blurred"
                    layout="fullWidth"
                    objectFit="cover"
                    quality={100}
                />
                <div className="darken-image"></div>
            </Hero>
            <div className="block-3x"></div>
            <Row >
                <div className="column is-3"></div>
                <div className="column is-6 pl-6 pr-6">
                    <p className="has-text-justified block">With over 30 years of teaching experience, Heidi has the ability to teach various levels of Yoga.</p>
                    <p className="has-text-justified block">She is a firm believer that only Advanced Teachers should be teaching Beginner classes. As a result, she offers Beginner Foundations Classes designed to prepare newcomers for more advanced practices in a safe and intelligent way.</p>
                    {/* <p className="has-text-justified block"></p> */}
                    <StaticImage
                        src={`../images/classes-2.jpg`}
                        alt="A picture of Heidi teaching yoga to students"
                        placeholder="blurred"
                        quality={100}
                        objectFit="cover"
                    />
                </div>
                <div className="column is-3"></div>
            </Row>
            <Row styles={`mt-2 mb-2 pt-5 pb-5 has-background-primary has-text-white is-family-secondary has-text-weight-medium`}>
                <div className="column is-4"></div>
                <div className="column is-4">
                    <p className="is-size-4 has-text-centered"><div className="has-text-weight-semi-bold is-inline-block">NEW CLASSES</div> start September 19<sup>th</sup></p>
                    <p className="is-size-4 has-text-centered">Home Studio - 145 Aspen Street, Paris  ON</p>
                </div>
                <div className="column is-4"></div>
            </Row>
            <Row styles={`mt-6 pb-6`}>
                <div className="column is-2"></div>
                <div className="column is-4">
                    <div className="card has-background-primary-light">
                        <div className="card-content">
                            <div className="title is-size-4 has-text-centered is-family-secondary">8 Week Session</div>
                            <div className="subtitle is-size-5 has-text-centered is-family-secondary">starting September 19</div>
                            <div className="content table has-background-primary-light">
                                <tbody>
                                    <tr>
                                        <td>Tuesdays</td>
                                        <td>9:30am to 11:00am</td>
                                        <td>General</td>
                                    </tr>
                                    <tr>
                                        <td>Wednesdays</td>
                                        <td>9:30am to 11:00am</td>
                                        <td>Beginner (Foundations)</td>
                                    </tr>
                                    <tr>
                                        <td>Wednesdays</td>
                                        <td>6:00pm to 7:15pm</td>
                                        <td>Beginner (Foundations)</td>
                                    </tr>
                                     <tr>
                                        <td>Wednesdays</td>
                                        <td>7:30pm to 8:45pm</td>
                                        <td>General</td>
                                    </tr>
                                </tbody>
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card has-background-primary-light">
                        <div className="card-content">
                        <div className="title is-size-4 has-text-centered is-family-secondary">Foundations Series</div>
                            <div className="subtitle is-size-5 has-text-centered is-family-secondary">starting September 19</div>
                            <div className="content">
                                The Foundations Series is designed to help build a safe and much more sustainable practice and is suitable for people who are new to Yoga as well as those who are returning to the mat. A detailed breakdown of poses as well as mindful breathing and meditation techniques is explored.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-2"></div>
            </Row>
            <Row>
                <div className="column is-12">
                    <StaticImage
                        src={`../images/about-3.jpg`}
                        alt="A picture of Heidi"
                        placeholder="blurred"
                        layout="fullWidth"
                        objectFit="cover"
                        quality={100}
                    />
                </div>
            </Row>
            <Row styles={`mt-2 mb-2 pt-5 pb-5 has-background-info is-family-secondary has-text-weight-semibold`}>
                <div className="column is-4"></div>
                <div className="column is-4">
                    {/* <p className="has-text-centered">Looking for a customized practice to best suit your needs? Let’s talk and see what we can create just</p>
                    <p className="has-text-centered">for YOU! Call or email Heidi at 519-755-1447 or email@email.com</p> */}
                    <p className="is-family-fancy is-size-1 has-text-white has-text-centered">Yoga is not about touching your toes,</p>
                    <p className="is-family-fancy is-size-1 has-text-white has-text-centered">it’s about what you learn on the way down.</p>
                    <p className="is-family-fancy is-size-3 has-text-white has-text-centered">- Jigar Gore</p>
                </div>
                <div className="column is-4"></div>
            </Row>
        </Layout>
    )
}